<template>
  <div class="card">
    <div class="card-header">
      <h6 style="display: inline-block;"><i class="fa-duotone fa-calendar-star"></i> Checkins</h6>  <input type="text" class="form-control form-control-sm" placeholder="Suche" v-model="search" style="display: inline-block;width: 200px;margin-left: 32px;font-size: 14px;"><select v-model="participationFilter" class="form-select form-select-sm" style="display: inline-block;   margin-left: 10px;width: 200px;">
      <option value="">Alle</option>
      <option value="absent">Nicht erschienen</option>
      <option value="complete">Vollständig anwesend</option>
      <option value="snooze">als abwesend markiert</option>
      <option value="incomplete">teilweise anwesend</option>
    </select>
      <button class="btn btn-outline-primary btn-sm float-end" @click="exportCSV()"><i class="fa-duotone fa-file-export"></i> Exportieren</button>
    </div>
    <div class="card-body">
<div style="height: 67vh;
    overflow: hidden scroll;">
      <table class="table-striped table table-sm stickyTable">
        <thead>
        <tr>
          <th>Mitglied</th>
          <th>Name</th>
          <th>Check-In</th>
          <th >Check-Out</th>
          <th @click="sortIt('participation')">Teilnahme</th>
          <th>Aktion</th>
          <th  @click="sortIt('le')">LE</th>
        </tr> </thead>
        <tbody>
        <tr v-for="(r, index) in paginatedCheckins" :key="r.customer.c_customer_id ? `${r.customer.c_customer_id}-${r.checkin_time}` : `no-id-${index}`">

          <td>{{ r.customer.c_customer_id }}</td>
          <td>{{ r.customer.c_lastname }} {{ r.customer.c_firstname }}</td>
          <template v-if="r.subeventsCheckin && Object.keys(r.subeventsCheckin).length > 0">
          <td>
            <template v-for="(subC, id) in r.subeventsCheckin"><small>{{$filters.formatDateTime(subC.checkin_time)}}</small><br></template>
          </td>
          <td>
            <template v-for="(subC, id) in r.subeventsCheckin"><small>{{$filters.formatDateTime(subC.checkout_time)}}</small><br></template>
          </td>
          </template>
          <template v-else>
            <td><small>{{ $filters.formatDateTime(r.checkin_time) }}</small></td>
            <td><small>{{ $filters.formatDateTime(r.checkout_time) }}</small></td>
          </template>
          <td>
            <template v-if=" r.participation === 'absent'"><span tooltip="Nicht erschienen" position="left" style="color: #b21f28;"><i class="fa-duotone fa-xmark-circle"></i></span></template>
            <template v-if="r.participation === 'complete'"><span tooltip="Vollständig anwesend" position="left" style="color: #3B8130;"><i class="fa-duotone fa-check-circle"></i></span></template>
            <template v-if="r.participation === 'snooze'"><span tooltip="als abwesend markiert" position="left" style="color: #fd7e14;"><i class="fa-duotone fa-timer"></i></span></template>
            <template v-if="r.participation === 'incomplete'"><span tooltip="teilweise anwesend" position="left" style="color: #fd7e14;"><i class="fa-duotone fa-timer"></i></span></template>
          </td>
          <td>

            <template v-if="r.participation === 'absent' || r.participation === 'snooze'">
              <template v-if="r._registrationState === '1'">


              <div class="btn-group">
                <button @click="stornoMember(r.registered_user,'stornoDataCheck')"
                        class="btn btn-outline-danger btn-sm" tooltip="Kostenpflichtig stornieren (no-show)" position="left"><i class="fa-duotone fa-xmark-circle"></i>
                </button>
                <button @click="stornoMember(r.registered_user,'stornoDataWithoutInvoiceCheck')"
                        class="btn btn-outline-secondary btn-sm" tooltip="kostenlos stornieren" position="left"><i class="fa-duotone fa-eye-slash"></i>
                </button>
              </div></template>
            </template>
          </td>
          <td>{{ r.le }}</td>

        </tr></tbody>


      </table>

</div>
    </div>
    <nav aria-label="Page navigation">
      <ul class="pagination justify-content-center">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)"><i class="fa-duotone fa-arrow-left"></i></a>
        </li>
        <li class="page-item" v-for="page in totalPages" :key="page" :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)"><i class="fa-duotone fa-arrow-right"></i></a>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: 'Checkins',
  props: {
    subevents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      checkins: [],
      direction: 'asc',
      search: '',
      currentPage: 1,
      itemsPerPage: 50, // Number of items to display per page
      participationFilter: ''
    }
  },
  components: {},
  watch: {
    search() {
      this.currentPage = 1; // Reset to the first page on search change
    },
    participationFilter() {
      this.currentPage = 1; // Reset to the first page on filter change
    }
  },
  computed: {
    filteredCheckins() {
      const searchTerm = this.search.toLowerCase();
      return this.checkins.filter((checkin) => {
        const customerId = checkin.customer?.c_customer_id?.toString().toLowerCase() || '';
        const fullName = `${checkin.customer?.c_lastname || ''} ${checkin.customer?.c_firstname || ''}`.toLowerCase();
        const matchesSearch = customerId.includes(searchTerm) || fullName.includes(searchTerm);
        const matchesParticipation = !this.participationFilter || checkin.participation === this.participationFilter;
        return matchesSearch && matchesParticipation;
      });
    },
    paginatedCheckins() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = this.currentPage * this.itemsPerPage;
      return this.filteredCheckins.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredCheckins.length / this.itemsPerPage);
    },
    ...mapGetters(["modalsList", "eventActive"])
  },
  methods: {
    sortIt($path) {


      if (this.direction === 'asc') {
        this.direction = 'desc';
      } else {
        this.direction = 'asc';
      }
      // sortCheckin alphabet
      this.checkins.sort((a, b) => {
        if (this.direction === 'asc') {
          return a[$path] > b[$path] ? 1 : -1;
        } else {
          return a[$path] < b[$path] ? 1 : -1;
        }
      });

    },
    exportCSV() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/checkins/export", {headers: headers})
        .then((response) => {
          const linkSource = 'data:text/csv;base64,' + response.data.result.data.file;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = 'checkins.csv';
          downloadLink.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    stornoMember($regId, type) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let payload = {
        activeDate: this.$route.params.id,
        activeSubDate: null,
        registered_user: $regId,
        type: type,
      }
      axios.post(this.$store.state.apiUrl + "date", payload, {headers}).then((r) => {
        this.$store.dispatch('toastSuccess', this.$t("toast.success.dataSaved"));
        this.getCheckins();
      });
    },
    getCheckins() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "event/" + this.eventActive.id + "/checkins", {headers: headers})
        .then((response) => {
          this.checkins = response.data.result.data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  created() {
    this.getCheckins();
  }
}
</script>

