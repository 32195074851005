<template>
  <div class="card w-100">
    <div class="card-header">
      <h6 style="display: inline; margin-right: 24px;">Freigaben</h6>
      <button class="float-end btn btn-sm me-3" :class="(showDeleted) ? 'btn-primary' : 'btn-outline-primary'"
              @click="toggleDeleted()" style="z-index: 25;position: relative;" position="left"
              tooltip="Alle Einträge anzeigen"><i class="fa-duotone fa-eye"></i></button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <template v-for="attest in attests" :key="attest.id">
            <div class="singleRequest"
                 v-if="(attest.verified !== 'denied' && attest.verified !== 'verified_complete' && !showDeleted) || (showDeleted && (attest.verified === 'verified_complete' || attest.verified === 'denied'))">
              <div class="pluginWrap insitutions">
                <div class="plugin-item">
                  <div class="lineitem l-date" style="font-size: 12px">{{ $filters.formatDate(attest.valid_from) }}
                  </div>
                  <div class="lineitem l-state">
                  <span class="stateIcon">
                    <template v-if="attest.verified ==='verified_waiting'">
                      <span tooltip="Akzeptiert #1" style="color: yellowgreen;"><i class="fa-duotone fa-check-circle"></i></span>
                    </template>
                    <template v-else-if="attest.verified ==='verified_complete'">
                      <span tooltip="Akzeptiert #2" style="color: green;"><i class="fa-duotone fa-check-circle"></i></span>
                    </template>
                    <template v-else-if="attest.verified ==='waiting'">
                      <span tooltip="Wartend" style="color: orange;"><i class="fa-duotone fa-clock"></i></span>
                    </template>
                    <template v-else>
                      <span tooltip="Abgelehnt" style="color: darkred;"><i
                        class="fa-duotone fa-xmark-circle"></i></span></template>
                     </span>
                  </div>
                  <div class="lineitem l-title" style="font-size: 12px">
                    {{ attest._attestTitle }}
                  </div>
                  <div class="lineitem l-title" style="font-size: 12px">
                    {{ attest._validState }}
                  </div>
                  <div class="lineitem l-title" style="font-size: 12px; vertical-align: top">
                    {{ attest._customerName }}
                  </div>
                  <div class="lineitem l-title" style="font-size: 12px; vertical-align: top">

                    {{ attest._verifiedWaitingBy }}<br>
                    {{ $filters.formatDateTime(attest._verifiedWaitingDate) }}
                  </div>
                  <div class="lineitem l-title" style="font-size: 12px; vertical-align: top">

                    {{ attest._verifiedBy }}<br>
                    {{ $filters.formatDateTime(attest._verifiedDate) }}
                  </div>

                  <div class="lineitem l-action l-preview">
                    <button @click="activeAttest = attest;getAttestFile(attest)" v-if="attest.verified ==='verified_waiting' || attest.verified === 'waiting'"
                            class="btn btn-outline-primary btn-xs float-end me-2"
                            tooltip="Vorschau" position="left"><i class="fa-duotone fa-eye"></i></button>
                  </div>
                  <div class="lineitem l-action ml-auto">
                    <button @click="denyAttest(attest)" class="btn btn-outline-danger btn-xs float-end me-2"
                            tooltip="Ablehnen" :disabled="(attest.verified === 'denied')"  position="left"><i
                      class="fa-duotone fa-xmark-circle"></i></button>
                    <button @click="checkAttest(attest)" class="btn btn-outline-success btn-xs float-end me-2"  v-if="attest.verified ==='verified_waiting' || attest.verified === 'waiting'"
                            :disabled="(attest.state ==='0')" tooltip="Akzeptieren" position="left"><i
                      class="fa-duotone fa-check-circle"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <!--Show Active Attest in Modal Preview with vuepdfembed-->
  <Modal v-if="activeAttest" @close="activeAttest = null;previewPdf=''">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5>{{ activeAttest._attestTitle }}</h5>
            <button class="btn-close" @click="activeAttest = null; previewPdf=''"></button>
          </div>
          <div class="modal-body" v-if="previewPdf">
            <vue-pdf-embed :source="previewPdf"/>
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <Modal v-if="showComment" @close="showComment = false">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5>Kommentar {{activeAttest._attestTitle}} - {{activeAttest._customerName}}</h5>
            <button class="btn-close" @click="showComment = false; activeAttest = null"></button>
          </div>
          <div class="modal-body">
            <textarea v-model="comment" class="form-control" rows="10"></textarea>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" @click="sendComment">Senden</button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import axios from "axios";
import VuePdfEmbed from "vue-pdf-embed";

export default {
  data() {
    return {
      activeAttest: null,
      attests: [],
      showDeleted: false,
      searchAttest: "",
      showAddAttest: false,
      addAttestTitle: "",
      comment: "",
      previewPdf: "",
      showComment: false
    };
  },
  components: {
    VuePdfEmbed
  },
  mounted() {
    this.getAttests();
  },
  methods: {
    toggleDeleted() {
      this.showDeleted = !this.showDeleted;
    },
    getAttests() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/attests", {headers}).then((r) => {
        this.attests = r.data.result.data;
      });
    },
    denyAttest(attest) {
      this.activeAttest = attest;
      this.showComment = true;
    },
    sendComment() {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let tempInst = this.activeAttest;
      tempInst.comment = this.comment;
      axios.put(this.$store.state.apiUrl + "trainer/attest/" + this.activeAttest.id + "/deny", tempInst, {headers}).then(() => {
        this.getAttests();
        this.showComment = false;
        this.activeAttest = null;
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.getAttests();
        this.showComment = false;
        this.activeAttest = null;
      });
    },
    checkAttest(attest) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      let tempInst = attest;
      tempInst.verified = "verified";
      axios.put(this.$store.state.apiUrl + "trainer/attest/" + attest.id + "/accept", tempInst, {headers}).then(() => {
        this.getAttests();
      }).catch((r) => {
        this.$store.dispatch("handleApiResponse", r.response);
        this.getAttests();
      });
    },
    getAttestFile(attest) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/attests/" + attest.id + "/file", {headers}).then((r) => {
        this.previewPdf = r.data.result.data.attest_pdf;
      });
    },
  },
};
</script>
