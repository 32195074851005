<template>
  <div class="modal fade show " tabindex="-1"
       aria-hidden="true" style="display: block;background: rgba(0, 0, 0, .4);text-align: left;">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
           Neue Supportanfrage</h5>
          <div class="btn-group" style="margin-left: auto;">
            <template v-if="loading">
              <button type="button" class="btn btn-sm btn-outline-primary" disabled>
                <i class="fa-duotone fa-spinner fa-spin"></i> {{ $t("globals.send") }}
              </button>
            </template>
            <template v-else>
              <button type="button" class="btn btn-sm btn-outline-primary" @click="postTask">
                <i class="fa-duotone fa-paper-plane"></i> {{ $t("globals.send") }}
              </button>
            </template>


          </div>
          <button type="button" class="btn-close ms-3" @click="closeTask"></button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="projectTitle">{{ $t("globals.title") }}</label>
            <input type="text" class="form-control" id="projectTitle" v-model="newTaskItem.title">
          </div>
          <p style="font-size: 12px;">{{$t('createTaskHint')}}</p>
          <div class="form-group">
            <label for="projectDesc">{{ $t("globals.description") }}</label>
            <Editor2 v-model="newTaskItem.description"></Editor2>
          </div>

          <div class="row">
            <div class="col-12">
              <input type="file" ref="fileInput" class="form-control" @change="onFileChange" accept="image/png,image/gif,image/jpeg,image/webp,application/pdf,image/svg+xml,text/plain,application/json,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/zip,application/x-rar-compressed,application/x-7z-compressed,audio/mpeg,audio/mp4,video/x-msvideo,video/x-flv,video/x-ms-wmv,video/mpeg,video/quicktime,message/rfc822,text/csv" />



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor2 from "@/components/Editor2.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import VueMultiselect from "vue-multiselect";

export default {
  name: "Create",
  computed: {
    // ...mapGetters(["customerActive", "stateFilter", "projectFilter", "getEmployees"]),
    ...mapGetters(["getUser", "getMenu"]),
  },
  components: { Editor2, VueMultiselect},
  data() {
    return {
      newTaskItem: {
        title: "",
        description: "",
        start_date: new Date().toISOString().substr(0, 10),
        due_date: "",
        project: {},
        task_id: '',
        state_id: 5,
        descVisible: '1',
        customer: {
          id: '',
          hash: '',
          full_name: ''
        },
        imageSource: '',
        imageMimeType: '',
        imageFileName:''
      },
      loading: false,
    };
  },
  methods: {
    postTask() {
      const headers = {"extAuth": localStorage.getItem("sketchManagerToken")};
      let VueInner = this;
      VueInner.loading = true;



      axios.post("https://api.sketch.media/" + "issue/", this.newTaskItem, {headers}).then((r) => {

        VueInner.closeTask();

        VueInner.$store.dispatch("toastSuccess", "Supportanfrage erfolgreich erstellt");
        VueInner.loading = false;

      }).catch((r) => {
        VueInner.$store.dispatch("handleApiResponse", r.response);
        VueInner.loading = false;
      });
    },
    onFileChange(){
      const fileInput = this.$refs.fileInput;
      const file = fileInput.files[0]; // Get the selected file
      let VueInner = this;
      if (file) {
        // Read the file as a Data URL to get base64 data
        const reader = new FileReader();
        const filename = file.name;
        reader.onload = () => {
          VueInner.newTaskItem.imageFileName = filename; // Set the image file name
          VueInner.newTaskItem.imageMimeType = file.type; // Set the image MIME type
          VueInner.newTaskItem.imageSource = reader.result.split(',')[1]; // Set the base64 image data
        };
        reader.readAsDataURL(file);
      }
    },
    closeTask() {
      this.$emit('close-task');
      this.$store.commit("closeModal", "task");
    },

  },
  created() {
    this.loading = false;

    if (this.taskActive) {
      this.newTaskItem = this.taskActive;
    }
  },
  mounted() {

  },
  watch: {
  }
}
</script>
<style scoped>
</style>
