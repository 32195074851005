<template>
  <template v-if="getUser && getUser._isEmployee && getEmployees">
    <span :tooltip="getUser.name" position="left" @click="toggleDropdown">
        <img :src="$filters.employeeIcon(getEmployees, getUser._isEmployee ,'id')"
             v-if="getEmployees && getUser && getEmployees" class="sketchAvatar ms-3"/>
</span>
    <ul class="dropdown-menu me-3" v-if="showMenu" @click="toggleDropdown" @mouseleave="showMenu = false">
      <h6 class="dropdown-header">
        <img :src="$filters.employeeIcon(getEmployees, getUser._isEmployee ,'id')" :tooltip="getUser.name"
             v-if="getEmployees && getUser && getEmployees" position="left" class="sketchAvatar"
             @click="toggleDropdown"/>
        {{ getUser.name }}</h6>
      <li>
        <hr class="dropdown-divider">
      </li>
      <!--     <li><button class="dropdown-item" @click="showProfile = true" ><i class="fa-duotone fa-address-card" style="margin-right: 6px;"></i> Meine Daten</button></li>-->
      <li>
        <button class="dropdown-item" @click="showPassword = true"><i class="fa-duotone fa-key"
                                                                      style="margin-right: 8px;"></i> Passwort ändern
        </button>
      </li>
      <li>
        <button class="dropdown-item" @click="showTwoFactor = true"><i class="fa-duotone fa-shield-check"
                                                                       style="margin-right: 8px;"></i> 2-Faktor Login
        </button>
      </li>
      <li>
        <button class="dropdown-item" @click="showCreateTask=true"><i class="fa-duotone fa-ticket" style="margin-right: 7px;"></i> Support Anfrage</button>
      </li>
      <li>
        <hr class="dropdown-divider">
      </li>
      <li>
        <button @click.prevent="logout()" class="dropdown-item"
        ><i class="fa-duotone fa-arrow-right-from-bracket" style="margin-right: 6px;margin-left: 3px;"></i> Logout
        </button>
      </li>
    </ul>
    <Password @close="showPassword = false" v-if="showPassword === true"/>
    <Profile @close="showProfile = false" v-if="showProfile === true"/>
    <TwoFactor @close="showTwoFactor = false" v-if="showTwoFactor === true"/>
    <CreateTask @close-task="showCreateTask = false" v-if="showCreateTask === true"/>
  </template>
</template>
<script>
import {mapActions} from "vuex";
import {mapGetters} from "vuex";
import Password from "./Password.vue";
import Profile from "./Profile.vue";
import TwoFactor from "./TwoFactor.vue";
import CreateTask from "./CreateTask.vue";

export default {
  name: "Userbar",
  data() {
    return {
      showMenu: false,
      showPassword: false,
      showProfile: false,
      showTwoFactor: false,
      showCreateTask: false,
    };
  },
  components: {Password, Profile, TwoFactor, CreateTask},
  computed: {
    ...mapGetters(["getUser", "getEmployees"]),
  },
  methods: {
    ...mapActions(["logout"]),
    toggleDropdown() {
      this.showMenu = !this.showMenu;
    },

  }
};
</script>
