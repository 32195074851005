<template>


  <div class="card">

    <h6 class="card-header">Aufnahmeprüfverfahren <button @click="activeQuestion = {id:'new'}" class="btn btn-outline-primary float-end"><i class="fa-duotone fa-plus"></i> Aufnahmeprüfverfahren anlegen</button></h6>

    <div class="card-body" v-if="!activeQuestion">
      <div class="row">
        <div class="col-12">
          <div class="singleRequest" v-for="request in questions" :key="request.id">

            <div class="card mb-3">

              <div class="card-body">

                <div class="row">

                  <div class="col-10">

                    <p class="text-secondary text-sm mb-0">{{ request.question }}</p>

                  </div>

                  <div class="col-2">

                    <button @click="activeQuestion = request" class="btn btn-outline-primary btn-xs float-end" tooltip="Editieren" position="left"><i class="fa-duotone fa-edit"></i></button>

                  </div>

                </div>

              </div>

            </div>


          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="row">
        <div class="col-12">
      <h5>{{ activeQuestion.name }}</h5>
      <button @click="activeQuestion = null" class="btn btn-danger float-end">Zur Übersicht</button>
      <button @click="postOrPut()" class="btn btn-success float-end me-3">Speichern</button>
      <button @click="deleteQuestion()" class="btn btn-warning float-end me-3">Löschen</button>
        </div>
      </div>
      <div class="row">

        <div class="col-12">

          <div class="form-group">

            <label>Frage</label>
            <input class="form-control" v-model="activeQuestion.question">

            <label>Punkte</label>
            <input class="form-control" v-model="activeQuestion.points">

            <label>Sortierung</label>
            <input class="form-control" v-model="activeQuestion.ordering">

              <label>Ist nur Überschrift</label>
            <select name="" class="form-control" v-model="activeQuestion.is_headline" id="">
              <option value="0">Nein</option>
              <option value="1">Ja, nur als Überschrift verwenden</option>
            </select>



          </div>

        </div>

      </div>

    </div>

  </div>


</template>
<script>
import axios from "axios";

export default {
  name: "DFBQuestions",


  components: {},

  props: {
    questionId: {
      type: String,
      default: null
    },
    requestId: {
      type: String,
      default: null
    }
  },

  methods: {

    deleteQuestion() {
      if (confirm("Frage wirklich entfernen? Wird nur empfohlen, wenn noch keine Bewerbung gelaufen ist! Folge: Darstellung der Aufnahmeprüfverfahren nicht mehr möglich") === true) {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "plugins/dfb/questions/" + this.activeQuestion.id, {headers}).then(() => {
          this.getQuestions();
          this.activeQuestion = null;
        });
      }
    },

    getQuestions() {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/questions?requestId="+this.requestId+"&questionId="+this.questionId, {headers}).then((r) => {
        this.questions = r.data.result.data;
        this.loading = false;
        this.activeQuestion = null;
      });

    },

    postOrPut() {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};


      if (this.activeQuestion.id === 'new') {
        this.activeQuestion.parent_id = this.questionId;
        this.activeQuestion.id = null;
        axios.post(this.$store.state.apiUrl + "plugins/dfb/questions", this.activeQuestion,{headers}).then((r) => {
          this.questions = r.data.result.data;
          this.loading = false;
          this.getQuestions();

        });
      }
      else {
        axios.put(this.$store.state.apiUrl + "plugins/dfb/questions", this.activeQuestion,{headers}).then((r) => {
          this.questions = r.data.result.data;
          this.loading = false;
          this.getQuestions();
        });
      }
    },



  },

  data() {
    return {
      questions: [],
      activeQuestion: null,

    };
  },

  created() {

    this.getQuestions();


  },

  watch: {
    questionId() {
      this.getQuestions();
    }
  }


};
</script>

<style lang="scss">

.product-item {
  cursor: pointer;
}

</style>
