<template>
  <div class="card">
  <div class="statsSingle newCustomers card-body">
    <h5>Runde Geburtstage</h5>
    <!-- Datum von bis filter -->

      <div class="row">
        <div class="col-6">
          <label for="from">Zeitraum: Von</label>
          <VueDatePicker v-model="filters.firstRow.from" model-type="yyyy-MM-dd" format="dd.MM.yyyy" placeholder="Von"
                         :auto-apply="true" :close-on-auto-apply="true" locale="de" :enable-time-picker="false"
                         cancel-text="X" select-text="Wählen" :teleport="true">
          </VueDatePicker>
        </div>

        <div class="col-6">
          <template v-if="stats && stats.length >0">

            <button class="btn btn-outline-primary mt-4" @click="addReport()">Bericht für {{stats.length}} Datensätze erstellen und öffnen</button>
          </template>
        </div>

      </div>


    <div class="row">
      <div class="col-12">
        <h5>Geburtstage</h5>
<h6>Jahre wählen:</h6>

        <div class="btn-group mb-3" role="group">

          <template v-for="(b, age) in filters.roundBirthdays">
            <input  class="btn-check" type="checkbox" :value="true" :id="'age_'+age" v-model="filters.roundBirthdays[age]">
            <label class="btn btn-outline-primary btn-sm" :for="'age_'+age">{{age}}</label>
          </template>
        </div>
        <div class="btn-group ms-2 mb-3" role="group">
          <button type="button" @click="toggleAll('all')"  class="btn btn-outline-primary btn-sm">Alle</button>
          <button type="button" @click="toggleAll('none')"  class="btn btn-outline-primary btn-sm">Keine</button>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-striped table-hover table-bordered">
    <thead>
    <tr>
      <th>Kunde</th>
      <th>Geburtstag</th>
      <th>Alter ist</th>
      <th>wird</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="stat in stats">
      <td class="small">{{ stat.c_firstname }} {{ stat.c_lastname }}</td>
      <td class="small">{{ $filters.formatDate(stat.c_birthday) }}</td>
      <td class="small">{{renderAge(stat.c_birthday)}}</td>
      <td class="small"><template v-if="renderAge(stat.c_birthday) !== stat.ageComming">{{stat.ageComming}}</template></td>
    </tr>
    </tbody>
  </table></div>
</template>
<script>
import axios from 'axios';
import {mapGetters} from "vuex";
import {LineChart, PieChart, BarChart} from 'vue-chart-3';
import VueDatePicker from '@vuepic/vue-datepicker';
import StatsTable from "@/routing/core/Stats/Statistics/StatsTable";

export default {
  name: 'newCustomersStats',
  components: {VueDatePicker, LineChart, PieChart, BarChart, StatsTable},
  computed:
    {
      ...mapGetters(["getEmployees"])
    },
  data() {
    return {
      stats: [],
      roundBirthdays: [10, 20, 30, 40, 50, 60, 65, 70, 75, 80, 85, 90, 95, 100],

      filters: {
        roundBirthdays:
          {
            10: true,
            20: true,
            30: true,
            40: true,
            50: true,
            60: true,
            65: true,
            70: true,
            75: true,
            80: true,
            85: true,
            90: true,
            95: true,
            100: true
          },
        firstRow: {
          from: null,
          to: null,
        },
        secondRow: {
          from: null,
          to: null,
        },
        employee: null,
        report:false,
      },
      chartData: {
        firstRow: {
          country: {},
          employee: {},
          type: {},
        },
        secondRow: {
          country: {},
          employee: {},
          type: {},
        },
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        interaction: {
          intersect: false,
          mode: "index",
        },
        scales: {
          y: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: true,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              padding: 10,
              color: "#fbfbfb",
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
          x: {
            grid: {
              drawBorder: false,
              display: false,
              drawOnChartArea: false,
              drawTicks: false,
              borderDash: [5, 5],
            },
            ticks: {
              display: true,
              color: "#ccc",
              padding: 20,
              font: {
                size: 11,
                family: "Open Sans",
                style: "normal",
                lineHeight: 2,
              },
            },
          },
        },
      },
    }
  },
  created() {
    this.getStat();
  },
  methods: {
    addReport() {

      this.filters.report = true;
      this.getStat(true);
    },
    toggleAll(action) {
     if(action === 'all'){
       for (let age in this.filters.roundBirthdays) {
         this.filters.roundBirthdays[age] = true;
       }
     }
      if(action === 'none'){
        for (let age in this.filters.roundBirthdays) {
          this.filters.roundBirthdays[age] = false;
        }
      }
    },
    generateRandomBackgroundColor() {
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },
    fillDefaultColors(data) {
      let i;
      for (i in data.firstRow.country) {
        data.firstRow.country[i].backgroundColor = this.generateRandomBackgroundColor();
      }
      return data;
    },
    renderAge(birthday) {
      let today = new Date();
      let birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    getStat(resetReport) {
      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      // filters as string
      let filters = "";
      if (!this.filters.firstRow.from) {
        // current month first day YYYY-MM-DD
        let date = new Date();
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 2);
        this.filters.firstRow.from = firstDay.toISOString().slice(0, 10);
      }
      if (!this.filters.firstRow.to) {
        // current month last day YYYY-MM-DD
        let date = new Date();
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.filters.firstRow.to = lastDay.toISOString().slice(0, 10);
      }
      filters += "?from=" + this.filters.firstRow.from;
      filters += "&to=" + this.filters.firstRow.to;
      if (this.filters.employee) {
        filters += "&employee=" + this.filters.employee;
      }
      // add filters.roundBirthdays
      let roundBirthdays = [];
      for (let age in this.filters.roundBirthdays) {
        if (this.filters.roundBirthdays[age]) {
          roundBirthdays.push(age);
        }
      }
      filters += "&roundBirthdays=" + roundBirthdays.join(",");
      // add filters.report
      if (this.filters.report) {
        filters += "&report=true"
      }
      axios.get(this.$store.getters.getApiUrl + "statistic/customers/birthdays" + filters, {headers}).then((r) => {
        this.stats = r.data.result.data.reduced;
        if (resetReport !== undefined) {
          this.filters.report = false;
          this.$store.state.filters.customers.report.value = r.data.result.data.report;
          this.$router.push({name: 'customers'});
        }
      }).catch((error) => {
        this.$store.dispatch("handleApiResponse", error.response);
      });
    }
  },
  watch: {
    filters: {
      handler: function () {
        this.getStat();
      },
      deep: true
    }
  }
}
</script>
