<template>


  <div class="card w-100">


    <h6 class="card-header">Erfahrungen <button @click="activeCalcType = {id:'new'}" class="btn btn-outline-primary btn-sm float-end"><i class="fa-duotone fa-plus"></i> Erfahrung anlegen</button></h6>

    <div class="card-body" v-if="!activeCalcType">
      <div class="row">
        <div class="col-12">
          <div class="singleRequest" v-for="request in calcTypes" :key="request.id">

            <div class="pluginWrap">

              <div class="plugin-item">

                <div class="lineitem l-title"> {{ request.title }}

                </div>
                  <div class="lineitem">

                    <span class="badge badge-danger w-100" v-if="request.nachweis_erforderlich === 'yes'">Nachweis erforderlich</span>

                    <span class="badge badge-success w-100" v-if="request.nachweis_erforderlich === 'no'">Kein Nachweis erforderlich</span>

                  </div>

                  <div class="lineitem">

                    <span class="badge badge-primary w-100">{{ request.question_type }}</span>

                  </div>



                  <div class="lineitem ml-auto">

                    <button @click="activeCalcType = request" class="btn btn-outline-primary btn-xs float-end" tooltip="Editieren" position="left"><i class="fa-duotone fa-edit"></i></button>

                  </div>



              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <div class="row">
        <div class="col-12">

      <h5>{{ activeCalcType.title }}</h5>
      <button @click="activeCalcType = null" class="btn btn-outline-secondary float-end "><i class="fa-duotone fa-question"></i> Zu den Aufnahmeprüfverfahren</button>
      <button @click="postOrPut()" class="btn btn-outline-success float-end me-3"><i class="fa-duotone fa-save"></i> Speichern</button>
      <button @click="deleteCalcType()" class="btn btn-outline-danger float-end me-3"><i class="fa-duotone fa-trash"></i> Löschen</button>
        </div>
      </div>
      <div class="row">

        <div class="col-12">

          <div class="form-group">

            <label>Titel</label>
            <input type="text" class="form-control" v-model="activeCalcType.title">

          </div>

        </div>

      </div>

      <div class="row">

        <div class="col-12">

          <label>Nachweis erforderlich?</label>
          <select class="form-control" v-model="activeCalcType.nachweis_erforderlich">

            <option value="yes">Ja</option>
            <option value="no">Nein</option>

          </select>
          <label>Antwort-Typ</label>
          <select class="form-control" v-model="activeCalcType.question_type">

            <option value="ausbildung">Ausbildung</option>
            <option value="trainerausbildung">Ausbildung Trainer</option>
            <option value="weiterbildung">Weiterbildung</option>

            <option value="nationalspieler">National Spieler</option>

            <option value="verbandstaetigkeit">Verbandstätigkeit</option>
            <option value="vereinsspieler">Verein Spieler</option>
            <option value="vereinstaetigkeit">Vereinstätigkeit</option>

            <option value="internationalspieler">International Spieler</option>
            <option value="internationaltrainer">International Trainer</option>
          </select>

        </div>

      </div>

      <div class="row">

        <div class="class-12">

          <label>Beschreibung</label>

          <Editor2 v-model="activeCalcType.description" />

        </div>

      </div>

 <Questions :questionId="activeCalcType.id" :requestId="requestId" />


    </div>

  </div>


</template>
<script>
import axios from "axios";
import Editor2 from "@/components/Editor2";
import Questions from "@/routing/plugins/dfb/subs/Questions.vue";

export default {
  name: "DFBCalcTypes",


  components: {Editor2, Questions},
  props: {
    requestId: {
      type: String,
      default: null
    },


  },


  methods: {

    deleteCalcType() {
      if (confirm("Gruppe wirklich entfernen? Wird nur empfohlen, wenn noch keine Bewerbung gelaufen ist! Folge: Darstellung der Aufnahmeprüfverfahren nicht mehr möglich") === true) {
        const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
        axios.delete(this.$store.state.apiUrl + "plugins/dfb/calcTypes/" + this.activeCalcType.id, {headers}).then(() => {
          this.getCalcTypes();
          this.activeCalcType = null;
        });
      }
    },

    getCalcTypes() {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};
      axios.get(this.$store.state.apiUrl + "plugins/dfb/calcTypes?requestId="+this.requestId, {headers}).then((r) => {
        this.calcTypes = r.data.result.data;
        this.loading = false;
      });

    },

    postOrPut() {

      const headers = {"crmAuth": localStorage.getItem("sketchManagerToken")};


      if (this.activeCalcType.id === 'new') {
        this.activeCalcType.request_id = this.requestId;
        this.activeCalcType.id = null;
        axios.post(this.$store.state.apiUrl + "plugins/dfb/calcTypes", this.activeCalcType,{headers}).then((r) => {
          this.requestTypes = r.data.result.data;
          this.loading = false;
          this.activeCalcType = null;
          // TODO Trigger Refresh in Parent

        });
      }
      else {
        axios.put(this.$store.state.apiUrl + "plugins/dfb/calcTypes", this.activeCalcType,{headers}).then((r) => {
          this.requestTypes = r.data.result.data;
          this.loading = false;
          this.activeCalcType = null;
        });
      }
    },



  },

  data() {
    return {
      calcTypes: [],
      activeCalcType: null,

    };
  },

  created() {

    this.getCalcTypes();


  },

  watch: {
    requestId() {

      this.getCalcTypes();
    }
  }


};
</script>

<style lang="scss">

.product-item {
  cursor: pointer;
}

</style>
